.projects {
  padding-top: 4em;
  padding-bottom: 3em;
}

.bloc-titles {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.h2-projects {
  color: #ac83ff;
}
.p-projects-title {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
}

.projects-list {
  margin-top: 1em;
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 2.5em;
}

.item-project {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 1.25rem;
  background-color: #fbfbfb;
  box-shadow: #e5d7ff 2px 3px 29px 2px;
  position: relative;
  -webkit-animation: swing-in-left-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation: swing-in-left-fwd 0.7s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes swing-in-left-fwd {
  0% {
    -webkit-transform: rotateY(100deg);
            transform: rotateY(100deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
}
@keyframes swing-in-left-fwd {
  0% {
    -webkit-transform: rotateY(100deg);
            transform: rotateY(100deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
}


.item-project:hover {
  box-shadow: #c3a1ff 5px 3px 30px 5px; 
}

.bloc-description {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.img-projects {
  width: 100%;
  border-radius: 1rem;
  height: 200px;
  object-fit: cover;
}

.title-projects {
  margin-top: 10px;
  text-align: left;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1.5;
}
.description-project {
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 13px;
  text-align: left;
  line-height: 1.5;
}

.bloc-buttons-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}

.projects-link {
  text-decoration: none;
  color: #ac83ff;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  border: none;
  background-color: transparent;
}

.icon-arrow {
  font-size: 25px;
}
.projects-link:hover .icon-arrow {
  transition: transform 0.3s ease;
  transform: translateX(3px); 
}

.filter-bloc-buttons {
  margin-top: 3em;
  margin-bottom: 3em;
  display: flex;
  flex-direction: row;
  gap: 3em;
}

.button-filter {
  height: 30px;
  padding: 15px;
  letter-spacing: 1px;
  border-radius: 0.5em;
  display: inline-flex;
  align-items: center;
  border: 1px solid  rgb(113, 113, 113);
  color:black;
  background-color: transparent;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.button-filter.active {
  border: 1px solid #ac83ff;
  background-color: #ac83ff;
  color: white;
  font-weight: 600;
}


.projects-link .icon-arrow {
  transition: transform 0.3s ease;
}
.custom-title {
  text-align: center;
  font-size: 24px;
  color: #ac83ff;
}

.custom-description {
  margin-top: 16px;
  font-size: 14px;
  color: black;
  text-align: center;
}
.bloc-description {
  margin-top: 1rem;
}
.bloc-stack-description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 2rem;

}

.check-stack {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.icon-check {
  font-size: 16px;
  color: #a981f7;
}
.custom-stack {
  margin-top: 16px;
  font-size: 12px;
  color: rgb(90, 90, 90);
}


@media screen and (max-width: 1200px) {


.filter-bloc-buttons {
justify-content: center;
}

.projects-list {
  grid-template-columns: repeat(2, 1fr);
}
}

@media screen and (max-width: 600px) {
  .filter-bloc-buttons {
    flex-wrap: wrap;
  }
  .projects-list  {
    display: flex;
    flex-direction: column;
  }
}