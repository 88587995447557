.presentation {
  padding-top: 4em;
  padding-bottom: 5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.presentation-title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 400px;
}

.profile-image-container {
  width: 350px;
  height: 350px;
  position: relative;
  margin-right: 30px;
}

.profile-image-circle {
  width: 300px;
  height: 300px;
  background-color: #d1bbff;
  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  right: -40px;
  z-index: -1;
}
.profile-image-circle-second {
  width: 300px;
  height: 300px;
  background-color: #fafafafa;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  right: -30px;
  z-index: -2;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.profile-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.profile-image:hover img {
  transform: scale(1.1);
}

.bloc-button {
  display: flex;
  flex-direction: row;
  gap: 3em;
  margin-top: 1em;
}

.contact-button,
.github-button {
  text-transform: uppercase;
  height: 50px;
  padding: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: background-color 0.3s ease;
  border-radius: 0.5em;
  display: inline-flex;
  align-items: center;
}

.contact-button {
  background-color: #ac83ff;
  color: white;
  border: none;
}

.github-button {
  background-color: #fafafafa;
  color: black;
  border: none;
}

.btn-icon {
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.github-button:hover {
  background-color: #e3e3e3;
}

.contact-button:hover {
  background-color: rgb(199, 172, 255);
}


@media screen and (max-width: 1200px) {
  .presentation {
    flex-direction: column-reverse;
    align-items: center;
    gap: 2em;
  }

  .presentation-title {
    width: 100%;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {

  .profile-image-container {
    width: 300px;
    height:300px;
  }
  .bloc-button {
    flex-direction: column;
    width:100%;
  }

  .contact-button,
.github-button  {
  justify-content: center;
}
}

