* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  background-color: #f1e9ff;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}
p {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.h1 {
  font-size: 80px;
  font-family: "Playfair Display", serif;
}


.h2 {
  font-size: 25px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
}

.h3 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  line-height: 1.5;
}
@media screen and (max-width: 650px) {
  .h1 {
    font-size: 60px;
  }
  .h2 {
    font-size: 20px;
  }
  .h3 {
    text-align: center;
    font-size: 17px;
  }
  }
