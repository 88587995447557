.navbar {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 20px;
	width: 500px;
	margin: 0 auto;
	background-color: #cdb5fc;
	opacity: 0.8;
	border-radius: 4rem;
	padding: 1rem;
	z-index: 1000;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	backdrop-filter: blur(15px);
      }
      
.link-nav {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px; 
    }
    
    .link-nav.active {
	background: #ad8cef;
	border-radius: 50%;
	padding: 8px;
	animation: bounce 0.5s;
}
 
@keyframes bounce {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.1);
	}
	100% {
	  transform: scale(1);
	}
      }

.icon-navbar {
	font-size: 23px;
}
    

@media screen and (max-width: 500px) {
.navbar {
	background-color: #c4aaf8;
	opacity: 0.9;
	width: 100%;
	bottom: 2px;
}
.link-nav{
	width:40px;
	height: 40px;
}
.icon-navbar {
	font-size: 20px;
}
}