.footer {
  background-color: #ac83ff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 2em;
  min-height: 250px;
  width: 100%;
}

.p-footer {
  margin-top: 7em;
  text-align: center;
  font-size: 15px;
  color: #fff;
}
